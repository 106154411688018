<template>
    <div class="creatorMajor-container">

        <div class="topColor"></div>
        <div class="creator-title">专业创作者</div>
        <div class="price">198元/月</div>
        <div class="interests-container">
            <div>
                <i class="iconfont icon-duihao"></i>
                无损音频下载
            </div>
            <div>
                <i class="iconfont icon-duihao"></i>
                每月多达<span>50</span>条BGM下载
            </div>
            <div>
                <i class="iconfont icon-duihao"></i>
                授权常见商业用途
            </div>
        </div>
        <div class="price-container">
            <div class="price-top">
                <div class="payPrice mouthPrice">
                    <span class="price-tip" v-if="$store.state.creatorMajorArr[0].sale_text">{{ $store.state.creatorMajorArr[0].sale_text }}</span>
                    <div class="realityPrice" @click="beComeVip('1月', $store.state.creatorMajorArr[0].goods_identifier)">
                        <span>¥</span>
                        <span v-if="$store.state.creatorMajorArr[0].sale_price">{{$store.state.creatorMajorArr[0].sale_price / 100}}</span>
                        <span v-else>{{$store.state.creatorMajorArr[0].price / 100}}</span>
                        <span>/月</span>
                    </div>
                    <span class="fixPrice" :style="!$store.state.creatorMajorArr[0].sale_price ? 'opacity:0;' : ''">¥{{$store.state.creatorMajorArr[0].price / 100}}</span>
                </div>
                <div class="payPrice quarterPrice">
                    <span  class="price-tip"  v-if="$store.state.creatorMajorArr[1].sale_text">{{ $store.state.creatorMajorArr[1].sale_text }}</span>
                    <div class="realityPrice"  @click="beComeVip('1季', $store.state.creatorMajorArr[1].goods_identifier)">
                        <span>¥</span>
                        <span v-if="$store.state.creatorMajorArr[1].sale_price">{{$store.state.creatorMajorArr[1].sale_price / 100}}</span>
                        <span v-else>{{$store.state.creatorMajorArr[1].price / 100}}</span>
                        <span>/季</span>
                    </div>
                <span class="fixPrice" :style="!$store.state.creatorMajorArr[0].sale_price ? 'opacity:0;' : ''">¥{{$store.state.creatorMajorArr[1].price / 100}}</span>
                </div>
            </div>
            <div class="price-bottom">
                <span class="price-tip"  v-if="$store.state.creatorMajorArr[2].sale_text">{{ $store.state.creatorMajorArr[2].sale_text }}</span>
                <div class="realityPrice"  @click="beComeVip('1年', $store.state.creatorMajorArr[2].goods_identifier)">
                    <span>¥</span>
                    <span v-if="$store.state.creatorMajorArr[2].sale_price">{{$store.state.creatorMajorArr[2].sale_price / 100}}</span>
                        <span v-else>{{$store.state.creatorMajorArr[2].price / 100}}</span>
                    <span>/年</span>
                </div>
                <span class="fixPrice" :style="!$store.state.creatorMajorArr[0].sale_price ? 'opacity:0;' : ''">¥{{$store.state.creatorMajorArr[2].price / 100}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    methods: {
        beComeVip(str, identifier) {
            this.$emit("transmitOrderInformation", "会员订阅-专业创作者-" + str, identifier)
        }
    }
}
</script>

<style lang="scss" scoped>
.creatorMajor-container{
    width: 288px;
    height: 388px;
    background: rgba(255, 255, 255, 0.8);
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
    .topColor{
        width: 288px;
        height: 8px;
        background: #4C68FB;
    }
    .creator-title{
        margin-top: 20px;
        text-align: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        letter-spacing: 0.025em;
        color: #000000;
    }
    .price{
        margin-top: 6px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #000000;
        opacity: 0.6;
    }
    .interests-container{
        margin: 38px 20px 30px;
        >div{
            i{
                margin-right: 10px;
            }
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.025em;
            color: #000000;
            opacity: 0.8;
        }
        >div:nth-child(2) {
            margin: 14px 0;
            span{
                color: #3976DB;
                font-size: 18px;
                line-height: 27px;
                font-weight: 500;
            }
        }
    }
    .current-authorization{
        padding: 0 20px;
        margin-top: 30px;
        div{
            cursor: pointer;
            height: 36px;
            border: 1px solid #000000;
            box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.025em;
            color: #fff;
            background: #000000;
        }
    }
    .price-container{
        padding: 0 20px;
        .price-top{
            display: flex;
            justify-content: space-between;
            .payPrice{
                position: relative;
                .price-tip{
                    display: inline-block;
                    position: absolute;
                    top: -5px;
                    right: -8px;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    transform:scale(0.75);
                    -ms-transform:scale(0.75);
                    -moz-transform:scale(0.75);
                    -webkit-transform:scale(0.75); 
                    -o-transform:scale(0.75); 
                    line-height: calc(13px / 0.75);
                    text-align: center;
                    color: #FFFFFF;
                    background: #F36565;
                    border-radius: 6px 0px;
                    padding: 0px 4px;
                    height: calc(13px / 0.75);
                }
                .fixPrice{
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    text-decoration-line: line-through;
                    color: rgba(0, 0, 0, 0.5);
                    background: none;
                }
                .realityPrice{
                    cursor: pointer;
                    width: 120px;
                    height: 36px;
                    border: 1px solid #000000;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 36px;
                    >span:nth-child(1){
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 22px;
                        text-align: center;
                        letter-spacing: 0.025em;
                        color: #090909;
                        opacity: 0.8;
                    }
                    >span:nth-child(2){
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        text-align: center;
                        letter-spacing: 0.025em;
                        color: #090909;
                    }
                     >span:nth-child(3){
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 22px;
                        text-align: center;
                        letter-spacing: 0.025em;
                        color: #090909;
                        opacity: 0.8;
                    }
                }
            }
        }
        .price-bottom{
            position: relative;
            .price-tip{
                display: inline-block;
                position: absolute;
                top: -5px;
                right: -8px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                transform:scale(0.75);
                -ms-transform:scale(0.75);
                -moz-transform:scale(0.75);
                -webkit-transform:scale(0.75); 
                -o-transform:scale(0.75); 
                line-height: calc(13px / 0.75);
                text-align: center;
                color: #FFFFFF;
                background: #F36565;
                border-radius: 6px 0px;
                padding: 0px 4px;
                height: calc(13px / 0.75);
            }
            .fixPrice{
                width: 100%;
                display: inline-block;
                text-align: center;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 15px;
                text-decoration-line: line-through;
                color: rgba(0, 0, 0, 0.5);
                background: none;
            }
            .realityPrice{ 
                cursor: pointer;
                height: 36px;
                border: 1px solid #000000;
                background: #000000;
                box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                text-align: center;
                line-height: 36px;
                >span:nth-child(1){
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    color: #fff;
                    opacity: 0.8;
                }
                >span:nth-child(2){
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    color: #fff;
                }
                >span:nth-child(3){
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    color: #fff;
                    opacity: 0.8;
                }
            }
        }
    }
}
</style>