<template>
    <div class="paymentResult-container">
        <div class="payment-title">
            支付<span v-if="paymentStatus">成功!</span><span v-else>超时</span>
        </div>
        <div class="paymentStatus-img">
            <img :src="paymentStatus ? 
            require('../../assets/paymentCenter/paymentSuccess.png') 
            : require('../../assets/paymentCenter/paymentFailed.png')"/>
        </div>
        <div class="paymentStatus-explain">
            <div v-if="paymentStatus" 
                :style=" paymentForm == 'userAddRange' || paymentForm == 'homePayBuyOnlyOne' 
                    ? '' : 'display: none;'">
                <div>
                    下载：  <span @click="downloadFile('ori_mp3')">MP3</span>   <span @click="downloadFile('wav')">WAV</span>
                </div>
                <div style="margin-top: 8px;">如无响应，可到<span @click="jumpUser">个人中心</span>再次点击下载</div>
            </div>
            <div v-else>请返回重新扫码付款</div>
        </div>
    </div>
</template>

<script>
import qs from 'qs'
import {axiosPost} from "../../../src/axios.js"
import {DOWNLOADFILE} from "../../api"
export default {
    props: {
        paymentStatus: Boolean,
        paymentForm: String,
        needPaymentMusicId: Number,
        musicName: String
    },
    mounted(){
    },
    methods: {
        jumpUser() {
            this.$emit("closePaymentCenterThiToParent");
            this.$router.push({path: '/user'});
        },
        downloadFile(str) {
            this.$emit("closePaymentCenterThiToParent", this.$props.needPaymentMusicId);

            // to do 模仿下载音频
            // setTimeout(()=> {
            //     localStorage.setItem("downloadMusicID", localStorage.getItem("downloadMusicID") + ',' + this.$props.needPaymentMusicId)
            //     this.$emit("payAudioLoadingOverThiToParent", this.$props.needPaymentMusicId)
            // },  10000)

            // return;

            let data = {
                musicId: this.$props.needPaymentMusicId,
                type: str
            }
            axiosPost(DOWNLOADFILE, qs.stringify(data))
            .then((res) => {
                if (res.data.path != null) {
                    this.$emit("payAudioLoadingOverThiToParent", res.data.music_id)
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.href = res.data.path;
                    a.download = this.$props.musicName + "." + (str == 'ori_mp3' ? 'mp3' : 'wav');;
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(res.data.path);
                }
            })
            .catch((err) => {
                if (err.data.code == -1) {
                    this.$message.error(err.data.msg);
                } else {
                    this.$message.error( "未知错误,请重新尝试!");
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.paymentResult-container{
    text-align: center;
    .payment-title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        letter-spacing: 0.025em;
        color: #000000;
    }
    .paymentStatus-img{
        margin-top: 16px;
        img{
        width: 280px;
        height: 73px;
        }
    }
    .paymentStatus-explain{
        margin-top: 54px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
        span{
            cursor: pointer;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 131%;
            letter-spacing: 0.025em;
            text-decoration-line: underline;
            text-transform: uppercase;
            color: #5490F4;
        }
    }
}
</style>