<template>
    <div class="companyPartner-container"  :style="currentAuthorization ? 'height: 355px;' : 'height: 388px;'">

        <div class="topColor"></div>
        <div class="creator-title">企业合作</div>
        <div class="price">客服电话: 010-53856622</div>
        <div class="interests-container" v-if="!currentAuthorization">
            <div>
                <i class="iconfont icon-duihao"></i>
                无损音频下载
            </div>
            <div>
                <i class="iconfont icon-duihao"></i>
                每月多达<span>100</span>条BGM下载
            </div>
            <div>
                <i class="iconfont icon-duihao"></i>
                授权常见企业级商业用途
            </div>
        </div>
        <div class="interestsContainer" v-if="currentAuthorization">
            <div>
                <i class="iconfont icon-duihao"></i>
                授权常见企业级商业用途
            </div>
            <div>
                <i class="iconfont icon-duihao"></i>
                APP内置/软件/游戏/智能硬件
            </div>
            <div>
                <i class="iconfont icon-duihao"></i>
                TVC/公开投放广告
            </div>
            <div>
                <i class="iconfont icon-duihao"></i>
                电视剧/电影/动画/综艺/演出
            </div>
            <div>
                <i class="iconfont icon-duihao"></i>
                商业线下活动
            </div>
        </div>
        <div class="priceContainer" :style="currentAuthorization ? 'padding-top: 0px;' : 'padding-top: 20px;'">
            参考价格：单曲¥99<span>｜</span>月¥499<span>｜</span>年¥3999
        </div>
        <div class="price-container"  :style="currentAuthorization ? 'margin-top: 13px;' : 'margin-top: 24px;'">
            <div class="realityPrice">
                请联系客服
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentAuthorization: Boolean
    },
}
</script>

<style lang="scss" scoped>
.companyPartner-container{
    width: 288px;
    background: rgba(255, 255, 255, 0.8);
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
    .topColor{
        width: 288px;
        height: 8px;
        background: #FB804C;
    }
    .creator-title{
        margin-top: 20px;
        text-align: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        letter-spacing: 0.025em;
        color: #000000;
    }
    .price{
        margin-top: 6px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #000000;
        opacity: 0.6;
    }
    .interests-container{
        margin: 38px 20px 28px;
        >div{
            i{
                margin-right: 10px;
            }
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.025em;
            color: #000000;
            opacity: 0.8;
        }
        >div:nth-child(2) {
            margin: 14px 0;
            span{
                color: #3976DB;
                font-size: 18px;
                line-height: 27px;
                font-weight: 500;
            }
        }
    }
    .interestsContainer{
        margin: 61px 20px 15px;
        margin: 34px 20px 15px;
        >div{
            i{
                margin-right: 10px;
            }
            height: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.025em;
            color: #000000;
            opacity: 0.8;
        }
        div:nth-child(2){
            margin: 8px 0;
        }
        div:nth-child(3){
            margin: 8px 0;
        }
        div:nth-child(4){
            margin: 8px 0;
        }
    }
    .priceContainer{
        // padding-top: 20px;
        text-align: center;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 131%;
        letter-spacing: 0.025em;
        color: #000000;
        opacity: 0.6;
        // background: #000000;
    }
    .price-container{
        padding: 0 20px;
        .realityPrice{ 
            height: 36px;
            border: 1px solid #000000;
            background: #000000;
            box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            text-align: center;
            line-height: 36px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.025em;
            color: #FFFFFF;
        }
    }
}
</style>