<template>
    <div class="paymentErcode-container">
        <div class="ercode-tab">
            <div class="tab-container">
                <span style="color:#36B144;"><i class="iconfont icon-weixinzhifu"></i>微信支付</span>
                <!-- <span :class="payWay == 'ali' ? 'choosedAli' : ''" @click="changePayWay('ali')">
                    <i class="iconfont icon-zhifubaozhifu"></i>
                    支付宝支付
                </span>
                <span :class="payWay == 'tencent' ? 'choosedTencent' : ''" @click="changePayWay('tencent')">
                    <i class="iconfont icon-weixinzhifu"></i>
                    微信支付
                </span> -->
            </div>
            <!-- <div class="tip-prompt"
                :style="payWay == 'ali' ? 
                'margin-left: 35.5px;background:#5490F4;' 
                : 'margin-left: 205px;background:#65DA79;'">
            </div> -->
            <div class="ercode-container">
                <img id="erCode"/>
            </div>
        </div>
        <div class="ercode-price">
            应付金额：<span>¥{{amount / 100}}</span>
        </div>
    </div>
</template>

<script>
import qs from 'qs';
import {send_record} from "../../utils/umeng_util";
import QRCode from 'qrcode';
import {GENERATEORDER, QUERYORDERSTATUS, GETUSERINFORMATION} from "../../../src/api.js"
import {axiosGet, axiosPost} from "../../../src/axios.js"
export default {
    props: {
        goodsIdentifier: String,
        needPaymentMusicId: Number
    },
    data () {
        return {
            payWay: "tencent",
            amount: 0,
            orderId: null,
            timer: null,
            orderStatus: false,
            timeCount: 0
        }
    },
    watch: {
        timeCount(val, oldVal) {
            if(val >= 900) {
                clearInterval(this.timer);
                this.timer = null;
                this.refreshQueryOrderStatus()
            }
        }
    },
    beforeMount() {
        this.getPaymentErcode(this.$props.goodsIdentifier)
    },
    mounted() {
    },
    methods: {
        getPaymentErcode(identifier) {
            let data;
            if(this.$props.needPaymentMusicId) {
                data = {
                    identifier: identifier,
                    musicId: this.$props.needPaymentMusicId
                }
            } else {
                data = {
                    identifier: identifier
                }
            }
            
            axiosPost(GENERATEORDER, qs.stringify(data))
                .then((res) => {
                    this.amount = res.data.amount
                    this.orderId = res.data.order_id
                    this.createErcode(res.data.code_url)
                }).catch( err => {
                    console.log('get price error', err)
                })
        },
        createErcode(url) {
            let opt = {
                type: "image/png",//生成的二维码类型
                margin: 0,
                quality: 0.3,//二维码质量
                width: 118,//宽
                height: 118,//
            }
            QRCode.toDataURL(url, opt, (err, url) => {
                if (err) {
                    console.log("get ercode",err);
                }
                this.$nextTick(() => {
                    document.getElementById("erCode").src = url;
                    // to do 模仿成功付款结束
                    // setTimeout(()=> {
                    //     this.$emit("paymentOver", true);
                    // }, 3000)
                    // return
                    this.queryOrderStatus()
                });
            });
        },
        clearTimer(){
            if(this.timer != null) {
                clearInterval(this.timer);
            }
        },
        queryOrderStatus() {
            this.timer = setInterval(()=> {
                axiosGet(QUERYORDERSTATUS, {
                    order_id: this.orderId
                }).then(res=> {
                    if(res.code == 0) {
                       this.orderStatus = true;
                       send_record("pay_center_pay_done");
                       if(this.$props.needPaymentMusicId != undefined){
                        localStorage.setItem("downloadMusicID", localStorage.getItem("downloadMusicID") + ',' + this.$props.needPaymentMusicId)
                       }
                       this.getUserGradeInf()
                    }
                    if(this.orderStatus){
                        clearInterval(this.timer)
                        this.timer = null;
                        this.$emit("paymentOver", this.orderStatus);
                    }
                }).catch(err => {
                    this.timeCount = this.timeCount + 2;
                })
            }, 2000)
        },
        refreshQueryOrderStatus() {
            let data = {
                order_id: this.orderId,
                refresh: 'force'
            }
            axiosGet(QUERYORDERSTATUS, data).then(res=> {
                    if(res.code == 0) {
                       this.orderStatus = true;
                       send_record("pay_center_pay_done");
                       if(this.$props.needPaymentMusicId != undefined){
                        localStorage.setItem("downloadMusicID", localStorage.getItem("downloadMusicID") + ',' + this.$props.needPaymentMusicId)
                       }
                       this.getUserGradeInf()
                       this.$emit("paymentOver", this.orderStatus);
                    }
                }).catch(err => {
                    this.orderStatus = false;
                    this.$emit("paymentOver", this.orderStatus);
                })
        },
        getUserGradeInf() {
            axiosGet(GETUSERINFORMATION)
                .then(res => {
                    let data = res.data;
                    this.$store.dispatch("triggerGetUserGrade", data.vip);
                    this.$store.dispatch("triggerGetDownNum", data['download_month_residue']);
                }).catch(err => {
                    this.$message.error(err.data.msg);
                    if(err.data.code == -1) {
                        // TODO:此时应该弹出登录
                    }
                })
        },
        changePayWay(str) {
            this.payWay = str;
        },
    }
}
</script>

<style lang="scss" scoped>
.paymentErcode-container{
    width: 269px;
    height: 296px;
    .ercode-tab{
        .tab-container{
            width: 269px;
            height: 38px;
            display: flex;
            justify-content: space-between;
            justify-content: center;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            span{
                cursor: pointer;
                font-family: 'Songti SC';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;
                color: #999999;
                i{
                    margin-right: 7px;
                }
            }
            .choosedAli{
                font-weight: 900;
                color: #5490F4;
            }
            .choosedTencent{
                font-weight: 900;
                color: #65DA79;
            }
        }
        .tip-prompt{
            width: 44px;
            height: 2px;
            border-radius: 2px;
            margin-top: -2px;
        }
        .ercode-container{
            margin: 24px 59.5px 26px;
            width: 150px;
            height: 150px;
            border-radius: 12px;
            background: #FFFFFF;
            padding: 16px;
            box-shadow: 5px 8px 19px rgba(0, 0, 0, 0.06);
            img{
                width: 118px;
                height: 118px;
            }
        }
    }
    .ercode-price{
        text-align: center;
        height: 58px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.025em;
        color: #999999;
        span{
            font-weight: 500;
            font-size: 24px;
            letter-spacing: 0.1px;
            color: #E55C5C;
        }
    }
}
</style>