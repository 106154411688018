<template>
    <div class="payment-container">
      <div class="activateEmpower-container" v-if="paymentForm.indexOf('userActivateEmpower') > -1">
        <div class="activateEmpower-top">
          <div class="close-overlay"  @click="closeOverlay">×</div>
          <div class="top-title">
            {{topTitle}}
          </div>
        </div>
        <div class="activateEmpower-bottom">
          <div class="explainFirst">
            注：请填写授权信息以完成激活，我们将为你出具授权证书并提供可下载的音频
          </div>
          <div class="information-top">
            <div class="top-title titleContaier">
              完善联系人信息
            </div>
            <div class="contacts inputArea">
              <div><span>*</span><span>联系人:</span></div>
              <div><input placeholder="请输入联系人" v-model="targetName"/></div>
            </div>
            <div class="telphone inputArea">
              <div><span>*</span><span>手机号码:</span></div>
              <div><input placeholder="请输入联系方式" max-length="11"
                pattern="[0-9]*"  
                v-number-only 
                type="telephone" 
                maxlength="11"
                v-model="phone"/></div>
            </div>
          </div>
          <div class="information-bottom">
            <div class="bottom-title titleContaier">
              完善授权信息
            </div>
            <div class="useScope inputArea">
              <div>选择用途</div>
              <div class="chooseScope">
                <div class="company chooseArea" @click="chooseUseArea(1)" 
                  v-if="!(paymentForm == 'userActivateEmpowerNoBusiness')"
                  >
                  <div class="box">
                    <span :style="purpose ? 'background: #5490F4;' : ''"></span>
                  </div>
                  公司使用
                </div>
                <div class="personal chooseArea" @click="chooseUseArea(0)">
                  <div class="box">
                    <span :style="!purpose ? 'background: #5490F4;' : ''"></span>
                  </div>
                  个人使用
                </div>
              </div>
            </div>
            <div class="company inputArea" v-if="purpose">
              <div><span>*</span><span>公司名称:</span></div>
              <div><input placeholder="请输入最终使用音乐的品牌公司名称" v-model="companyName"/></div>
            </div>
            <div class="projectTitle inputArea">
              <div><span>*</span><span>项目名称:</span></div>
              <div><input placeholder="例：xx产品广告、xx企业宣传片" v-model="projectName"/></div>
            </div>
            <div class="deliveryChannels inputArea">
              <div><span>*</span><span>投放渠道:</span></div>
              <div><input placeholder="例：包括但不限于抖音、微博、微信、优酷等各大网络平台" v-model="pushChannel" /></div>
            </div>
          </div>
          <div class="explainSecond" style="margin-top: 20px;">
            *授权项目信息填写不清晰可能导致授权书无效，请根据项目实际情况如实且详尽的填写授权信息以保证您的合法权益
          </div>
          <div class="explainSecond" style="margin-top: 2px;">
            *提交授权信息后不可修改，请确认授权信息无误
          </div>
          <div class="submitButtton">
            <v-btn class="ma-2 createButton" 
              :loading="loading" 
              color="secondary"
              @click="submitLisense">
              提交
            </v-btn>
          </div>
        </div>
      </div>
      <div class="submitLisense-container" 
        id="submitLisense-container"
        v-if="paymentForm.indexOf('userActivateEmpower') > -1"
        style="display:none;"
        >
        <div class="reminder-information">
          <div class="close-overlay"  @click="closeSubmitContainer">×</div>
          <div class="img">
            <img src="../assets/paymentCenter/reminder_infor.png"/>
          </div>
          <div class="reminder-title">注意</div>
          <div class="reminder-text">提交授权信息后不可修改<br/>请确认授权信息无误</div>
          <div class="reminder-button">
            <span @click="closeSubmitContainer">取消</span>
            <span @click="submitLisenseConfirm">确认</span>
          </div>
        </div>
      </div>
      <div class="paymentCenter" v-else>
        <div class="paymentCenter-top">
          <div class="close-overlay"  @click="closeOverlay">×</div>
          <!-- 有tab的时候minPaddingTop 无tab的时候maxPaddingTop-->
          <div class="top-title" 
            :class="paymentForm.indexOf('home') > -1 && $store.state.userGrade == 0
             ? 'minPaddingTop' : 'maxPaddingTop'" >
            {{topTitle}}
            <div class="attention" v-if="paymentForm == 'userRenew'">
              <img src="../assets/paymentCenter/attention.png"/>
              <div class="attention-container">
                <div class="jiantou"></div>
                <div class="attention-detail">
                  <div>如果您想获得其他授权的BGM（非商用/商用/企业合作），可以:</div>
                  <div>1. 单曲购买并选择您想要的授权；</div>
                  <div>2. 在个人中心的购买记录中选择增加授权并选择您想要的授权；</div>
                  <div>3. 等到当前会员到期后选择您想要授权的会员档位订购；</div>
                </div>
              </div>
            </div> 
          </div>
          <!-- home-tab -->
          <div class="home-tab top-tab" 
            v-if="paymentForm.indexOf('home') > -1 && $store.state.userGrade == 0">
            <span
              :class="payDetailClassify == 'BecomeVip' ? 'choosedTab' : ''"
              @click="payUseScope('BecomeVip')"
              >
              会员订购
            </span>
            <span :class="payDetailClassify == 'BuyOnlyOne' ? 'choosedTab' : ''"
              @click="payUseScope('BuyOnlyOne')"
              >
              单曲购买
            </span>
          </div>
          <div class="jumpStepFirst" @click="jumpStepFirst"  v-if="paymentStep == 2">
            ←重新选择套餐
          </div>
        </div>
        <div class="paymentCenter-bottom">
          <div class="paymentCenter-step">
            <span :class="paymentStep >= 1 ? 'completeStep' : ''">01 选择支付范围 </span>
            <span :class="paymentStep >= 2 ? 'completeStep' : ''"> ------ 02 支付 </span>
            <span :class="paymentStep == 3 ? 'completeStep' : ''"> ------ 03 完成</span>
          </div>
          <div class="paymentCenter-step-first" v-if="paymentStep == 1">
            <!-- home&user 开通 -->
            <div class="paymentUseRange paymentUseRange1"
                v-if="payDetailClassify == 'BecomeVip' 
                || paymentForm == 'userBecomeVip'">
              <creator style="margin-right: 41.5px;"
                @transmitOrderInformation="transmitOrderInformation">
              </creator>
              <creator-major style="margin-right: 41.5px;"
                @transmitOrderInformation="transmitOrderInformation">
              </creator-major>
              <company-partner></company-partner>
            </div>
            <!-- home buyOne / user增加授权-->
            <div class="paymentUseRange paymentUseRange2" 
              v-if="payDetailClassify== 'BuyOnlyOne' 
              || paymentForm == 'userAddRange'" >
                <no-business 
                  style="margin-right: 41.5px;"
                  @transmitOrderInformation="transmitOrderInformation">
                </no-business>
                <business style="margin-right: 41.5px;" 
                   @transmitOrderInformation="transmitOrderInformation">
                </business>
                <company-partner :currentAuthorization="true"></company-partner>
            </div>
            <!-- 会员续费 -->
            <div class="paymentUseRange paymentUseRange3" 
              v-if="paymentForm == 'userRenew'">
              <creator style="margin-right: 41.5px;"
                v-if="$store.state.userGrade == 1"
                @transmitOrderInformation="transmitOrderInformation"
                >
                </creator>
              <creator-major style="margin-right: 41.5px;"
                v-if="$store.state.userGrade == 2"
                @transmitOrderInformation="transmitOrderInformation"
                >
                </creator-major>
            </div>
          </div>
          <div class="payOrder" :style="paymentStep >= 2 ? '' : 'display:none;'">
            订单信息：{{orderInformation}}
          </div>
          <div class="paymentCenter-step-second" v-if="paymentStep == 2">
            <payment-ercode class="payment-ercode" 
              ref="paymentErcode"
              :needPaymentMusicId="needPaymentMusicId"
              :goodsIdentifier="goodsIdentifier"
              @paymentOver="paymentOver">
            </payment-ercode>
          </div>
          <div class="paymentCenter-step-third" v-if="paymentStep == 3">
            <payment-result :paymentStatus="paymentStatus" 
              :paymentForm="paymentForm" 
              :needPaymentMusicId="needPaymentMusicId"
              :musicName="musicName"
              @closePaymentCenterThiToParent="closePaymentCenterThiToParent"
              @payAudioLoadingOverThiToParent="payAudioLoadingOverThiToParent">
            </payment-result>
          </div>
        </div>
      </div>
    </div>
    <!-- 返回修改  确认提交 -->
</template>

<script>
import qs from 'qs';
import { send_record } from "../utils/umeng_util.js";
import NoBusiness from './paymentComponent/noBusiness'
import Business from './paymentComponent/business'
import CompanyPartner from './paymentComponent/companyPartner'
import Creator from './paymentComponent/creator'
import CreatorMajor from './paymentComponent/creatorMajor'
import PaymentErcode from './paymentComponent/paymentErcode'
import PaymentResult from './paymentComponent/paymentResult'
import {axiosGet, axiosPost} from "../../src/axios.js"
import {GETLICENSEGEN} from "../../src/api.js"
export default {
  components: {
    NoBusiness,
    Business,
    CompanyPartner,
    Creator,
    CreatorMajor,
    PaymentErcode,
    PaymentResult
  },
  props: {
    paymentForm: String,
    needPaymentMusicId: Number,
    musicName: String
  },
  directives: {
    numberOnly: {
      bind: function(e) {
        e.handler = function() {
          e.value = e.value.replace(/\D+/, '')
        }
        e.addEventListener('input', e.handler)
      },
      unbind: function(e) {
        e.removeEventListener('input', e.handler)
      }
    }
  },
  data() {
    return {
      payDetailClassify: null,

      topTitle: '付费中心',

      orderInformation: "会员订阅-专业创作者-一年",
      paymentStep: 1,
      paymentStatus: false, // 付款成功或者失败
      goodsIdentifier: null,
      
      purpose: 1,
      targetName: '',
      phone: '',
      companyName: '',
      projectName: '',
      pushChannel: '',
      loading: false
    }
  },
  watch: {
    needPaymentMusicId: function(val, oldVal) {
      this.reSetAllData();
    },
    paymentForm: function(val, oldVal) {
      this.reSetAllData()
    }
  },
  beforeMount() {
    this.judgePayFrom();
  },
  mounted() {
  },
  activated() {},
  methods: {
    closeSubmitContainer() {
      document.getElementById("submitLisense-container").style.display = "none";
    },
    submitLisenseConfirm() {
      document.getElementById("submitLisense-container").style.display = "none";
      this.$emit("closePaymentCenter", -100);
      this.$emit("setCurrentMusicLicense", this.$props.needPaymentMusicId,null)
      this.loading = true;
      let data = {
        purchase_id: this.$props.needPaymentMusicId,
        target_name: this.targetName,
        phone: this.phone,
        purpose: this.purpose, 
        company_name: this.companyName,
        project_name: this.projectName,
        push_channel: this.pushChannel
      }
      axiosPost(GETLICENSEGEN, qs.stringify(data))
        .then(res => {
          this.loading = false;
          this.$emit("setCurrentMusicLicense", this.$props.needPaymentMusicId,res.data.cert_url)
        }).catch(err => {
          console.log(err)
          this.loading = false;
        })
    },
    submitLisense() {
      if(this.purpose) {
        if(this.targetName.length == 0 
          || this.phone.length == 0
          || this.companyName.length == 0 
          || this.projectName.length == 0 
          || this.pushChannel.length == 0)
        {
          this.$message.warning("请完整填写信息");
          return;
        }
      } else {
        if(this.targetName.length == 0
          || this.phone.length == 0 
          || this.projectName.length == 0 
          || this.pushChannel.length == 0)
        {
          this.$message.warning("请完整填写信息");
          return;
        }
      }
      document.getElementById("submitLisense-container").style.display = "block";
    },
    closePaymentCenterThiToParent(id) {
      this.$emit("closePaymentCenter", id);
    },
    payAudioLoadingOverThiToParent(downloadAudioId){
      this.$emit("payAudioLoadingOver", downloadAudioId);
    },
    closeOverlay() {
      if(this.paymentStep == 2){
        this.$refs.paymentErcode.clearTimer()
      }

      if(this.paymentStatus){
        this.$emit("closePaymentCenter", this.$props.needPaymentMusicId);
      } else{
        this.$emit("closePaymentCenter", -100);
      }

      if(this.paymentStep == 1 || this.paymentStep == 2) {
        this.$emit("payAudioLoadingOver", -100)
      }else if(this.paymentStep == 3 && this.paymentStatus && this.$props.needPaymentMusicId) {
        this.$emit("payAudioLoadingOver", this.$props.needPaymentMusicId)
      }
    },
    payUseScope(str) {
      if(str == "UpgradeQuality") {
        this.paymentStep = 1;
      } else {
        this.paymentStep = 1;
      }
      this.payDetailClassify = str
    },
    transmitOrderInformation(str,identifier) {
      this.orderInformation = str;
      send_record("pay_center_paying",{goods_identifier: identifier})
      this.goodsIdentifier = identifier;
      this.paymentStep = 2;
    },
    
    // 模拟一下付款成功或者失败
    paymentOver(tip) {
      this.paymentStatus = tip;
      if(this.$props.paymentForm == "userAddRange") {
        this.$emit("queryPaymentHistory")
      }
      this.paymentStep = 3;
    },
    jumpStepFirst() {
      if(this.paymentStep == 2){
        this.$refs.paymentErcode.clearTimer()
      }
      this.paymentStep = 1;
    },
    judgePayFrom() {
      let definePageShow = this.$props.paymentForm;
      if(definePageShow=='')
      return
      if(definePageShow.indexOf("home") > -1) {
        this.topTitle = "付费中心"
        if(definePageShow.indexOf("BecomeVip") > -1) {
          this.payDetailClassify = "BecomeVip"
        }else if(definePageShow.indexOf("BuyOnlyOne") > -1) {
          this.payDetailClassify = "BuyOnlyOne"
        } 
      } else if(definePageShow.indexOf("user") > -1) {
        if(definePageShow == "userAddRange") {
          this.topTitle = "增加授权";
          this.payDetailClassify = '';
        } else if(definePageShow == "userBecomeVip") {
          this.topTitle = "会员订阅";
          this.payDetailClassify = '';
        } else if(definePageShow == "userRenew"){
          this.topTitle = "会员续费"
          this.payDetailClassify = "";
        } else if(definePageShow.indexOf("userActivateEmpower") > -1) {
          if(definePageShow == "userActivateEmpowerNoBusiness") {
            this.purpose = 0;
          } else {
            this.purpose = 1;
          }
          this.topTitle = "激活音乐授权";
          this.payDetailClassify = '';
        }
      }
    },
    chooseUseArea(str) {
      this.purpose = str;
    },
    reSetAllData() {
      this.payDetailClassify = null;
      this.topTitle = '付费中心';
      this.orderInformation = "会员订阅-专业创作者-一年";
      this.paymentStep = 1;
      this.paymentStatus = false;
      this.goodsIdentifier = null;
      this.purpose = 1;
      this.targetName =  '';
      this.phone =  '';
      this.companyName = '';
      this.projectName = '';
      this.pushChannel = '';
      this.loading = false;
      this.judgePayFrom();
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-container{
  width: 100vw;
  min-width: 1440px;
  height: 100vh;
  min-height: 700px;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 5;
  .activateEmpower-container{
    position: absolute;
    margin:auto;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 1043px;
    height: 679px;
    background: radial-gradient(59.61% 93.78% at 49.95% 116.14%, rgba(55, 62, 230, 0.10) 0%, rgba(106, 135, 239, 0.00) 100%), #FFF;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    .activateEmpower-top{
      width: 1043px;
      height: 89px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background: url(../assets/paymentCenter/top-background.png);
      background-size: 1043px 89px;
      overflow: hidden;
      .close-overlay{
        cursor: pointer;
        position: absolute;
        right: 12px;
        line-height: 34px;
        font-size: 40px;
        font-weight: 350;
      }
      .top-title{
        padding-top: 37px;
        font-family: PingFang SC;
        font-style: Medium;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: #000;
      }
    }
    .activateEmpower-bottom{
      width: 1043px;
      height: 580px;
      padding: 26px 74px 38px;
      .inputArea{
        display: flex;
        margin-top: 10px;
        >div:nth-child(1) {
          width: 86px;
          text-align: right;
          margin-right: 22px;
          line-height: 38px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.025em;
          color: rgba(0, 0, 0, 0.6);
          >span:nth-child(1) {
            color: #F36565;
            margin-right: 4px;
          }
        }
        >div:nth-child(2) {
          width: 787px;
          height: 38px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 2px;
          padding: 8px;
          input{
            border: none;
            outline: none;
            width: 771px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.12em;
            color: rgba(0, 0, 0, 0.7);
          }
          input::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
      .titleContaier{
        margin: 20px 0 14px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.025em;
        color: rgba(0, 0, 0, 0.8);
      }
      .useScope{
        .chooseScope{
          display: flex;
          border: none !important;
          .chooseArea{
            cursor: pointer;
            margin-right: 18px;
            display: flex;
            .box{
              top: 2px;
              position: relative;
              width: 16px;
              height: 16px;
              border: 1.5px solid rgba(0, 0, 0, 0.6);
              border-radius: 4px;
              margin-right: 8px;
              span{
                position: relative;
                top: -3px;
                left: 1px;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 2px;
              }
            }
          }
        }
      }
      .explainFirst{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.025em;
        color: rgba(0, 0, 0, 0.6);
      }
      .explainSecond{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.025em;
        color: #F36565;
      }
      .submitButtton{
        margin-top: 10px;
        text-align: center;
        .createButton{
          width: 240px;
          height: 44px;
          overflow: hidden;
          margin-left: 0px !important;
          font-size: 20px;
          background: #000 !important;
          border-radius: 20px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          padding: 0 !important;
          margin: 0 !important;
        }
      }
      .custom-loader {
        animation: loader 1s infinite;
        display: flex;
      }
      @-moz-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @-webkit-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @-o-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
  .submitLisense-container{
    position: absolute;
    margin:auto;
    top:0;
    left:0;
    width: 100vw;
    min-width: 1440px;
    height: 100vh;
    min-height: 700px;
    background: rgba(0, 0, 0, 0.4);
    .reminder-information{
      position: absolute;
      margin:auto;
      top:0;
      left:0;
      right:0;
      bottom:0;
      width: 304px;
      height: 380px;
      background: #FFFFFF;
      border-radius: 12px;
      text-align: center;
      .close-overlay{
        font-size: 30px;
        color: #aaafbc;
        text-align: right;
        padding-right: 15px;
        margin-top: 15px;
        height: 20px;
        line-height: 20px;
      }
      .img{
        margin-top: 23px;
        img{
          width: 48px;
          height: 48px;
        }
      }
      .reminder-title{
        margin-top: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.128571px;
        color: #333333;
      }
      .reminder-text{
        margin-top: 20px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #F36565;
      }
      .reminder-button{
        margin-top: 71px;
        display: flex;
        justify-content: center;
        span{
          cursor: pointer;
          text-align: center;
          display: inline-block;
          width: 107px;
          height: 44px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 44px;
          letter-spacing: 0.1px;
        }
        span:nth-child(1) {
          border: 1px solid #000000;
          border-radius: 20px;
        }
        span:nth-child(2) {
          margin-left: 8px;
          color: #fff;
          background: #000;
          border-radius: 20px;
        }
      }
    }
  }
  .paymentCenter{
    position: absolute;
    margin:auto;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 1043px;
    height: 635px;
    border-radius: 16px;
    background: radial-gradient(59.61% 93.78% at 49.95% 116.14%, rgba(55, 62, 230, 0.10) 0%, rgba(106, 135, 239, 0.00) 100%), #FFF;
    box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    .paymentCenter-top{
      width: 1043px;
      height: 126px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background: url(../assets/paymentCenter/top-background.png);
      background-size: 100% 100%;
      .close-overlay{
        cursor: pointer;
        position: absolute;
        right: 12px;
        line-height: 34px;
        font-size: 40px;
        font-weight: 350;
      }
      .top-title{
        font-family: PingFang SC;
        font-style: Medium;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: #000;
        .attention{
            text-align: left;
            display: inline;
            position: relative;
            z-index: 3 !important;
            img{
              width: 18px;
              height: 18px;
            }
            .attention-container{
              position: absolute;
              left: -7px;
              display: none;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.025em;
              color: rgba(255, 255, 255, 0.9);
              .jiantou{
                width: 0;
                height: 0;
                border-bottom: 14px solid rgba(0, 0, 0, 0.8);
                border-right: 14px solid transparent;
                border-left: 14px solid transparent;
              }
              .attention-detail{
                margin-left: -212.5px;
                padding: 14px 16px;
                width: 453px;
                background: rgba(0, 0, 0, 0.8);
                border-radius: 16px;
              }
            }
          }
          .attention:hover{
              cursor: pointer;
              .attention-container{
                  display: block;
                  z-index: 3 !important;
              }
          }
      }
      .minPaddingTop {
        padding-top: 26px;
      }
      .maxPaddingTop {
        padding-top: 55px;
      }
      .top-tab{
        padding: 2px;
        width: 296px;
        height: 42px;
        margin-top: 16px;
        margin-left: 373.5px;
        text-align: center;
        line-height: 38px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 14px;
        span{
          cursor: pointer;
          display: inline-block;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.025em;
          width: 146px;
          height: 38px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
        .choosedTab{
          border-radius: 12px;
          background: #fff;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
        }
      }
      .jumpStepFirst{
        cursor: pointer;
        position: absolute;
        top: 92px; 
        left: 48px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 128.5%;
        letter-spacing: 0.025em;
        color: #000000;
      }
    }
    .paymentCenter-bottom{
      width: 1043px;
      height: 509px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      .paymentCenter-step{
        // background: #000;
        padding: 13px 0 14px 48px;
        span{
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.025em;
          color: rgba(0, 0, 0, 0.4);
        }
        .completeStep{
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          letter-spacing: 0.025em;
          color: #5490F4;
        }
      }
      .paymentCenter-step-first{
        .paymentUseRange{
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
      }
      .payOrder{
        margin: 0px 0 0 48px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 128.5%;
        letter-spacing: 0.025em;
        color: rgba(0, 0, 0, 0.4);
      }
      .paymentCenter-step-second{
        .payment-ercode{
          margin: 54px 388px 0;
        }
      }
      .paymentCenter-step-third{
        margin-top: 74px;
      } 
    }
  }
}
</style>