<template>
    <div class="noBusiness-container">
        <div class="topColor"></div>
        <div class="creator-title">非商业用途</div>
        <div class="interests-container">
            <div>
                <i class="iconfont icon-duihao"></i>
                授权非商业用途及NFT
            </div>
        </div>
        <div class="price-container">
            <div class="realityPrice" @click="BuyOnlyOne">
                <span>¥</span>
                <span>29</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    mounted() {
    },
    methods: {
        BuyOnlyOne() {
            this.$emit("transmitOrderInformation", "单曲购买-个人非商用", 'single_music')
        }
    }
}
</script>

<style lang="scss" scoped>
.noBusiness-container{
    width: 288px;
    height: 355px;

    background: rgba(255, 255, 255, 0.8);
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow: hidden;
    .topColor{
        width: 288px;
        height: 8px;
        background: #2FAA4A;
    }
    .creator-title{
        margin-top: 20px;
        text-align: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
        letter-spacing: 0.025em;
        color: #000000;
    }
    .interests-container{
        margin: 200px 20px 12px;
        >div{
            i{
                margin-right: 10px;
            }
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.025em;
            color: #000000;
            opacity: 0.8;
        }
    }
    .price-container{
        padding: 0 20px;
        .current-authorization{
            cursor: pointer;
            height: 36px;
            border: 1px solid #000000;
            box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.025em;
            color: #000000;
        }
        .realityPrice{ 
            cursor: pointer;
            height: 36px;
            border: 1px solid #000000;
            background: #000000;
            box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            text-align: center;
            line-height: 36px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.025em;
            color: #FFFFFF;
            >span:nth-child(1) {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.8);
            }
        }

    }
}
</style>