var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyPartner-container",style:(_vm.currentAuthorization ? 'height: 355px;' : 'height: 388px;')},[_c('div',{staticClass:"topColor"}),_c('div',{staticClass:"creator-title"},[_vm._v("企业合作")]),_c('div',{staticClass:"price"},[_vm._v("客服电话: 010-53856622")]),(!_vm.currentAuthorization)?_c('div',{staticClass:"interests-container"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.currentAuthorization)?_c('div',{staticClass:"interestsContainer"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e(),_c('div',{staticClass:"priceContainer",style:(_vm.currentAuthorization ? 'padding-top: 0px;' : 'padding-top: 20px;')},[_vm._v(" 参考价格：单曲¥99"),_c('span',[_vm._v("｜")]),_vm._v("月¥499"),_c('span',[_vm._v("｜")]),_vm._v("年¥3999 ")]),_c('div',{staticClass:"price-container",style:(_vm.currentAuthorization ? 'margin-top: 13px;' : 'margin-top: 24px;')},[_c('div',{staticClass:"realityPrice"},[_vm._v(" 请联系客服 ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-duihao"}),_vm._v(" 无损音频下载 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-duihao"}),_vm._v(" 每月多达"),_c('span',[_vm._v("100")]),_vm._v("条BGM下载 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-duihao"}),_vm._v(" 授权常见企业级商业用途 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-duihao"}),_vm._v(" 授权常见企业级商业用途 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-duihao"}),_vm._v(" APP内置/软件/游戏/智能硬件 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-duihao"}),_vm._v(" TVC/公开投放广告 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-duihao"}),_vm._v(" 电视剧/电影/动画/综艺/演出 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-duihao"}),_vm._v(" 商业线下活动 ")])
}]

export { render, staticRenderFns }